<div [ngStyle]="{'margin-bottom': settings.listSpacing+'px'}">
  <p *ngIf="hasUrl">
    <a class="title" [ngStyle]="{'font-size': settings.titleFontSize+'px'}" href="{{item.url}}" [attr.target]="settings.openLinkInNewTab ? '_blank' : null" [attr.rel]="settings.openLinkInNewTab ? 'noopener' : null">
      {{item.title}}
    </a>
    <span *ngIf="item.domain" class="domain">({{item.domain}})</span>
  </p>
  <p *ngIf="!hasUrl">
    <a class="title" [ngStyle]="{'font-size': settings.titleFontSize+'px'}" [routerLink]="['/item', item.id]" routerLinkActive="active">
      {{item.title}}
    </a>
  </p>
  <div class="subtext-palm">
    <div class="details" *ngIf="item.type !== 'job'">
      <span class="name"><a [routerLink]="['/user', item.user]" routerLinkActive="active">{{item.user}}</a></span>
      <span class="right">{{item.points}} ★</span>
    </div>
    <div class="details">
    {{ item.time_ago }}
    <a [routerLink]="['/item', item.id]" routerLinkActive="active" class="comment-number" *ngIf="item.type !== 'job'"> •
      {{item.comments_count | comment }}
    </a>
    </div>
  </div>
  <div class="subtext-laptop">
    <span *ngIf="item.type !== 'job'">
      {{item.points}} points by
      <a [routerLink]="['/user', item.user]" routerLinkActive="active">{{item.user}}</a>
    </span>
    <span [class.item-details]="item.type !== 'job'">
      {{ item.time_ago }}
      <span *ngIf="item.type !== 'job'"> |
        <a [routerLink]="['/item', item.id]" routerLinkActive="active">
          {{item.comments_count | comment }}
        </a>
      </span>
    </span>
  </div>
</div>
