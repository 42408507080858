<div id="popup1" class="overlay">
	<div class="popup">
		<h1>Settings</h1>
		<hr>
		<span class="close" (click)="closeSettings()">&times;</span>
		<div class="content">
			<h2>Links</h2>
			<input type="checkbox" [checked]="settings.openLinkInNewTab" (change)="toggleOpenLinksInNewTab()" />
			  Open links in a new tab

				<div class="theme-controls">
				<h2>Select a theme</h2>
				<div>
					<label>
						<input #default
												name="theme"
												type="radio"
												value="default"
												[checked]="settings.theme === default.value"
												(click)="selectTheme(default.value)" />
						Default
					</label>
				</div>
				<div>
					<label>
						<input #night
												name="theme"
												type="radio"
												value="night"
												[checked]="settings.theme === night.value"
												(click)="selectTheme(night.value)" />
						Night
					</label>
				</div>
				<div>
					<label>
						<input #amoledblack
												name="theme"
												type="radio"
												value="amoledblack"
												[checked]="settings.theme === amoledblack.value"
												(click)="selectTheme(amoledblack.value)" />
						Black (AMOLED)
					</label>
				</div>
          <h2>Change Font</h2>
          <div>
            <label>
              Font size:
              <input #titleFont
                     min="1"
                     [value]="settings.titleFontSize"
                     name="theme"
                     type="number"
                     (keyup)="changeTitleFont(titleFont.value)" />
            </label>
          </div>
          <div>
            <label>
              List spacing:
              <input #listSpacing
                     min="0"
                     [value]="settings.listSpacing"
                     name="theme"
                     type="number"
                     (keyup)="changeSpacing(listSpacing.value)" />
            </label>
          </div>
			</div>
		</div>
	</div>
</div>
